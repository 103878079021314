@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color1: #C80201;
  --color2: #37474f;
  --color3: #f44336;
}

@layer components {
  .container_xxl{
    @apply max-w-[1536px] mx-0 px-8
  }
  .row{
    @apply flex flex-wrap
  }
  .titleHeading{
    @apply text-4xl font-semibold py-5 text-gray-700 text-center
  }
  .heading{
    @apply text-4xl font-semibold py-5 text-gray-700
  }
  .headingBorder__b{
    @apply border-b border-gray-600
  }
  .label {
    @apply mb-2 text-base font-semibold inline-block text-gray-700
  }

  .input {
    @apply w-full border border-gray-400 focus:outline-none focus-within:shadow px-3 py-2 rounded placeholder:italic placeholder:text-sm
  }

  .text_area {
    @apply border min-h-[6rem] rounded px-3 py-2 focus:outline-none focus-within:shadow placeholder:italic placeholder:text-sm w-full
  }

  .mandatory {
    @apply text-2xl text-red-800
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eceff1;
}


.primaryBtn-outlined{
  border: 1px solid var(--color3) !important;
  color: var(--color3) !important;
}

.hide_show_switch .MuiSwitch-switchBase.Mui-checked{
  color: var(--color3) !important;
}
.hide_show_switch .Mui-checked+.MuiSwitch-track{
  background-color: var(--color3) !important;
}

.file-uploader{
  width: 100%;
  max-width: 100% !important;
  height: 10rem !important;
  padding: 0 5rem !important;
  border: 1px dashed var(--color3)
}
.file_upload{
  width: 75% !important;
  max-width: 100% !important;
  height: 10rem !important;
  padding: 0 5rem !important;
  border: 1px dashed var(--color3)
}

.input-container{
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;

}


.pageNumber{
  border: 1px solid var(--color3);
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedPageNumber{
  background-color: var(--color3);
  color: #fff;
}
.lastPage{
  border: none;
  cursor: not-allowed;
  border-radius: 100%;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}